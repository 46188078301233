import React, { useEffect, useState } from "react";
import {
  CAMPAIGNPRODUCTS,
  CAMPAIGNPRODUCTSFilterproduct,
} from "../../store/Compaign/Action";
import { useTranslation } from 'react-i18next';
import { useStateValue } from "../../store/state";
import { useParams } from "react-router-dom";
import "../../components/ProductCard/ProductCard.scss";
import "./CampaignProduct.scss";
import { FiMenu } from "react-icons/fi";
import { Drawer } from "../../components/Drawer/Drawer";
import request from "../../request";
import InfiniteScroll from "react-infinite-scroll-component";
import NewCardSkeleton from "../../components/Skeleton/NewCardSkeleton/NewCardSkeleton";
import ProductCard from "../../components/ProductCard/ProductCard";
import CampaignSidebar from "./CampaignSidebar";

function CampaignProduct() {
  const { CDN_PRODUCT_BASE_URL, CDN_BASE_URL, CDN_BASE_URL2, CDN_PRODUCT_CAMPAIGN } = request;
  const [
    { campaignProducts, campaignProductsCount, bannerImage, filterTitle, filterDetails, thaiTitle, filterdetailsthai, responsiveImage },
    dispatch,
  ] = useStateValue();
  const { t } = useTranslation()
  const { Id, categoryId } = useParams();
  const [loader, setLoader] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("all");
  const [filteredProducts, setFilteredProducts] = useState([]); // Keep track of all loaded products
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [autoLoader, setAutoLoader] = useState(1);
  const [refreshAPI, setRefreshAPI] = useState(false);
  const [currentSelection, setCurrentSelection] = useState({
    ID: "",
    subID: "",
  });
  const [PLPData, setPLPData] = useState([]);
  const [mobileCategory, setMobileCategory] = useState("");
  const [currentpage, setCurrentPage] = useState(1); // Initialize STOREID state

  const [initialLoad, setInitialLoad] = useState(false);
  const [containerClass, setContainerClass] = useState("container"); // Default class name

  // Function to handle window resize
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update class name based on window width
  useEffect(() => {
    if (windowWidth <= 768) {
      setContainerClass("containerdata"); // Class for mobile
    } else {
      setContainerClass("container"); // Class for desktop
    }
  }, [windowWidth]);

  const isMobile = windowWidth <= 768;

  const toggleFilterModal = () => {
    setIsFilterModalOpen(!isFilterModalOpen);
  };

  const handleMainMenuClick = (menu) => {
    setSelectedMenu(menu);
    const filters = campaignProducts
      .filter((product) => product.mainmenu === menu)
      .flatMap((product) => product.filtermenu);
    setCurrentFilters([...new Set(filters)]);
    toggleFilterModal();
  };
  const handleFilterClick = (filter) => {
    setSelectedFilter(filter === selectedFilter ? null : filter);

    let filtered = campaignProducts.filter((product) =>
      product.filtermenu.includes(filter)
    );

    setFilteredProducts(filtered);
  };

  useEffect(() => {
    setCurrentPage(1); // Reset to page 1
    setFilteredProducts([]); // Clear previous filtered products
    dispatch({ type: 'SET_CATEGORY' }); // Reset campaign products

    let TOKEN = localStorage.getItem("token");
    let COMPANYID = localStorage.getItem("companyid");
    let USERID = localStorage.getItem("userid");
    let STOREID = localStorage.getItem("storeid");
    let COUNTRYDATA = "CountryX";
    let BRANDDATA = "BrandY";
    // Fetch new campaign products based on the new Id and categoryId
    CAMPAIGNPRODUCTS(
      dispatch,
      TOKEN,
      COMPANYID,
      STOREID,
      USERID,
      Id,
      STOREID,
      categoryId,
      currentSelection.ID,
      currentpage,
      BRANDDATA,
      COUNTRYDATA,
      "BrandY",
      "coffeeshop",
      "household",
      "sme"
    );
  }, [Id, categoryId]);

  useEffect(() => {

    if (currentpage > 1 && currentpage <= Math.ceil(campaignProductsCount / 20)) {
      // if (currentpage > 1) {
      let TOKEN = localStorage.getItem("token");
      let COMPANYID = localStorage.getItem("companyid");
      let USERID = localStorage.getItem("userid");
      let STOREID = localStorage.getItem("storeid");
      let COUNTRYDATA = "CountryX";
      let BRANDDATA = "BrandY";

      CAMPAIGNPRODUCTS(
        dispatch,
        TOKEN,
        COMPANYID,
        STOREID,
        USERID,
        Id,
        STOREID,
        categoryId,
        currentSelection.ID,
        currentpage,
        BRANDDATA,
        COUNTRYDATA,
        "BrandY",
        "coffeeshop",
        "household",
        "sme"
      );
    }
  }, [currentpage]);

  const loadNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  // Call loadNextPage when scrolling to the bottom of the page
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadNextPage();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    let filtered = campaignProducts;
    if (searchQuery) {
      filtered = filtered.filter(
        (product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.sku.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredProducts(filtered);
  }, [selectedMenu, selectedCategory, campaignProducts, searchQuery, dispatch]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const mainMenu = [
    ...new Set(campaignProducts.map((product) => product.mainmenu)),
  ];
  // const filterMenu = selectedMenu
  //   ? [...new Set(filteredProducts.flatMap((product) => product.filtermenu))]
  //   : [];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    setCurrentPage(1);
    setFilteredProducts([]);
    dispatch({ type: 'SET_CATEGORY' });

    let TOKEN = localStorage.getItem("token");
    let COMPANYID = localStorage.getItem("companyid");
    let USERID = localStorage.getItem("userid");
    let STOREID = localStorage.getItem("storeid");
    let COUNTRYDATA = "CountryX";
    let BRANDDATA = "BrandY";
    // Fetch new campaign products based on the new Id and categoryId
    if (currentSelection.ID && currentSelection.subID) {
      CAMPAIGNPRODUCTS(
        dispatch,
        TOKEN,
        COMPANYID,
        STOREID,
        USERID,
        Id,
        STOREID,
        categoryId,
        currentSelection.ID,
        currentpage,
        BRANDDATA,
        COUNTRYDATA,
        "BrandY",
        "coffeeshop",
        "household",
        "sme"
      );
    }

  }, [currentSelection]);

  useEffect(() => {
    if (campaignProducts?.products) {
      // setFilteredProducts((prevProducts) => [
      //   ...prevProducts,
      //   ...campaignProducts.products,
      // ]);

      campaignProducts?.products?.filter(data => (
        setFilteredProducts(prevState => [
          ...prevState,
          data
        ])
      ))
    }
  }, [campaignProducts]);


  return (
    <div className={containerClass}>
      <div className="compaign-product-card">
        <div className="banner">
          {bannerImage && (
            <>
              {windowWidth <= 768 ? (
                <img src={`${CDN_PRODUCT_CAMPAIGN}${responsiveImage}`} alt="Campaign Banner" />
              ) : (
                <img src={`${CDN_PRODUCT_CAMPAIGN}${bannerImage}`} alt="Campaign Banner" />
              )}
            </>
          )}
        </div>
        <div className="header-text">
          <h4 style={{ fontWeight: "bold" }}>
            {localStorage.getItem("storeid") === "2" ? thaiTitle : filterTitle}
          </h4>
          <span className="compaignspan-text">{localStorage.getItem("storeid") === "2" ? filterdetailsthai : filterDetails}</span>
          <div class="search_box_wrapper">
            <input
              class="search_input"
              placeholder={`${t("SEARCHPLACEHOLDER")}`}
              name="srch-term"
              id="srch-term"
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button class="search_btn">
              <i className="fa fa-search"></i>
            </button>
          </div>
          {windowWidth <= 912 && (
            <aside className="main-menu">
              <CampaignSidebar
                products={campaignProducts}
                Id={Id}
                currentPage={currentpage}
                categoryId={categoryId}
                currentSelection={currentSelection}
                setCurrentSelection={setCurrentSelection}
                mobileCategory={mobileCategory}
                setMobileCategory={setMobileCategory}
                type="mobile"
              />
            </aside>
          )}
        </div>

        <div className="main-and-products">

          {windowWidth > 912 && (
            <aside className="main-menu">
              <CampaignSidebar
                products={campaignProducts}
                Id={Id}
                currentPage={currentpage}
                categoryId={categoryId}
                currentSelection={currentSelection}
                setCurrentSelection={setCurrentSelection}
                mobileCategory={mobileCategory}
                setMobileCategory={setMobileCategory}
                type="web"
              />
            </aside>
          )}

          <div className="campaign-products-container">
            <div className="PRODUCTPLP__DATA my_product_section">
              <div className="PRODUCTPLP__CONTENT">
                <div className="PRODUCTPLP__DATA">
                  {!loader && filteredProducts.length === 0 && (
                    <p className="no_product">{"NOPRODUCTAVAILABLE"}</p>
                  )}
                  <InfiniteScroll
                    dataLength={filteredProducts?.length}
                    next={loadNextPage} // Call loadNextPage to increment currentPage
                    // hasMore={autoLoader * 20 < campaignProducts?.count}
                    // loader={
                    //   loader &&
                    //   [...Array(20)].map((_, index) => (
                    //     <NewCardSkeleton devicetype={"mobile"} key={index} />
                    //   ))
                    // }
                    // hasMore={Math.ceil(campaignProductsCount/20) == currentpage ? false : true}
                    hasMore={
                      filteredProducts?.length < campaignProductsCount // Check if products loaded < total count
                      && currentpage < Math.ceil(campaignProductsCount / 20) // Check if there are more pages
                    }
                    loader={loader && [...Array(20)].map((_, index) =>
                      <NewCardSkeleton
                        devicetype={window.innerWidth > 912 ? "desktop" : "mobile"}
                        key={index} />)}
                    scrollThreshold={0.5}
                  >
                    {filteredProducts.map((product, index) => (
                      <ProductCard
                        key={index}
                        data={product}
                        setRefreshAPI={setRefreshAPI}
                        refreshAPI={refreshAPI}
                        devicetype={
                          windowWidth > 912 ? "desktop" : "mobile"
                        }
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignProduct;