import React, { useCallback, useEffect, useState } from 'react';
import ProductLeftContent from '../Product/ProductLeftContent';
import { useStateValue } from '../../store/state';
import { useTranslation } from 'react-i18next';
import Favourite from '../../assets/images/MyProduct/favorite_border.png'
import FavouriteActive from '../../assets/images/MyProduct/favorite_border_active.png'
import bookmark from '../../assets/images/MyProduct/bookmark_border.png'
import bookmarkActive from '../../assets/images/MyProduct/bookmark_border_active.png'
import Vector from '../../assets/images/MyProduct/Vector.png'
import VectorActive from '../../assets/images/MyProduct/Vector_active.png'
import shoppingBag from '../../assets/images/MyProduct/shopping_bag.png';
import shoppingBagActive from '../../assets/images/MyProduct/shopping_bag_active.png';
import statusIcon from '../../assets/images/myreminder/Status_Icon.png';
import MyReminder_icon from '../../assets/images/MyProduct/MyReminder_icon.png';
import active_MyReminder_icon from '../../assets/images/MyProduct/active_MyReminder_icon.png';
import { debounce } from '@mui/material'
import axios from 'axios';
import request from '../../request';
import InfiniteScroll from 'react-infinite-scroll-component';
import NewCardSkeleton from '../../components/Skeleton/NewCardSkeleton/NewCardSkeleton';
import ProductCard from '../../components/ProductCard/ProductCard';
import './MyReminder.scss';

// Image mapping for active and inactive states in sidebar_1
const images = {
    MyProduct: { inactive: shoppingBag, active: shoppingBagActive },
    MyPurchase: { inactive: Vector, active: VectorActive },
    MyWishlist: { inactive: bookmark, active: bookmarkActive },
    MyReminder: { inactive: MyReminder_icon, active: active_MyReminder_icon },
    MyFavourite: { inactive: Favourite, active: FavouriteActive },
};

const MyReminder = () => {
    const [loader, setLoader] = useState(false);
    const [beforeSearchPLPData, setBeforeSearchPLPData] = useState([]);
    const [PLPData, setPLPData] = useState([]);
    const [PLPDataCount, setPLPDataCount] = useState([]);
    const [initialLoad, setInitialLoad] = useState(false);
    const [autoLoader, setAutoLoader] = useState(1)
    const { t } = useTranslation()
    const [refreshAPI, setRefreshAPI] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [daysSelect, setDaysSelect] = useState(null);
    const [currentSelection, setCurrentSelection] = useState({});
    const [filter, setFilter] = useState('All'); // New state for filter

    let TOKEN = localStorage.getItem('token');
    let COMPANYID = localStorage.getItem('companyid');
    let USERID = localStorage.getItem('userid');
    let STOREID = localStorage.getItem('storeid');

    // API call here - Search, Auto loader
    const fetchProducts = async () => {
        try {
            const prodslidData = await axios({
                method: "get",
                url: `${request.getwishlist}${USERID}&store_id=${STOREID}&page_no=${autoLoader}&search=${searchQuery || 0}`,
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            });
            //   setwishlistCount(prodslidData.data[0].count);
            //   settrending(prodslidData.data[1]);

            const newProducts = prodslidData.data?.[1] ?? [];
            setPLPDataCount(prodslidData.data?.count)

            if (autoLoader === 1 && searchQuery) {
                if (newProducts) {
                    setPLPData(newProducts);
                }
            } else {
                newProducts?.filter(data => (
                    setPLPData(prevState => [
                        ...prevState,
                        data
                    ])
                ))
                newProducts?.filter(data => (
                    setBeforeSearchPLPData(prevState => [
                        ...prevState,
                        data
                    ])
                ))
            }
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setLoader(false);
        }
    };


    useEffect(() => {
        setLoader(true);
        fetchProducts();
    }, [STOREID]);

    // Effect to fetch initial products or update when autoLoader changes
    useEffect(() => {
        if (autoLoader > 1) {
            fetchProducts();
        }
    }, [autoLoader]);

    // Handle search button click
    const handleSearchClick = () => {
        setLoader(true);
        fetchProducts();
    };

    // Debounced fetch function
    // const debouncedFetchProducts = useCallback(debounce(() => {
    //     fetchProducts();
    // }, 300), []);


    // // Handle search input change
    const handleSearchChange = (event) => {
        // setAutoLoader(1)
        const query = event?.target?.value;
        setSearchQuery(query);
        // if (query) {
        // } else {
        //     debouncedFetchProducts();
        //     setLoader(true);
        //     setPLPData([]);
        // }
        if (query) {
            setPLPData(PLPData.filter(item =>
                item.name.toLowerCase().includes(query.toLowerCase()) ||
                item.brand_name.toLowerCase().includes(query.toLowerCase()) ||
                item.sku.toLowerCase().includes(query.toLowerCase())
            ));
        } else {
            setPLPData(beforeSearchPLPData); // Reset to show all products if no query
        }
    };

    // Handle filter change
    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
        if (newFilter === 'All') {
            setPLPData(beforeSearchPLPData);
        } else if (newFilter === 'Available') {
            setPLPData(beforeSearchPLPData.filter(item => item?.order_taking === 'N'));
        } else if (newFilter === 'Unavailable') {
            setPLPData(beforeSearchPLPData.filter(item => item?.order_taking === 'Y'));
        }
    };



    const loadNextPage = () => {
        setAutoLoader(prevAutoLoader => prevAutoLoader + 1);
    }

    return (
        <div className='PRODUCTPLP'>
            <div className='PRODUCTPLP__HEADER'>
                {
                    window.innerWidth > 912 &&
                    <ProductLeftContent currentSelection={currentSelection} setCurrentSelection={setCurrentSelection} setPLPData={setPLPData} initialLoad={initialLoad} setInitialLoad={setInitialLoad} loader={true} setLoader={setLoader} type="web" activeSec="MyReminder" />
                }
                <div className='PURCHASEPLP_RIGHT_SIDE'>
                    <div className="my_product_content_header">
                        <div className="my_product_content_header_first">
                            {window.innerWidth > 912  ? 
                                <div className='heading'> {t("My Reminder")}</div> :
                                window.innerWidth <= 912 && localStorage.getItem('device') !== 'true' && 
                                <div className='heading_mobile'>
                                    {t("My Reminder")}
                                    <span>
                                        <a href="/myproduct"><img src={images.MyProduct.inactive} /></a>
                                        <a href="/mypurchase"><img src={images.MyPurchase.inactive} /></a>
                                        <a href="/myfavourite"><img src={images.MyFavourite.inactive} /></a>
                                    </span>
                                </div>
                            }
                            <div className='info'>
                                {t("Don't forget to put any out of stock product under your wishlist. Get notified once it's restocked")}
                            </div>
                        </div>
                        <div class="search_box_wrapper">
                            <input
                                class="search_input"
                                placeholder={t("Search Product, Brand")}
                                name="srch-term"
                                id="srch-term"
                                type="text"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        handleSearchClick();
                                    }
                                }}
                            />
                            <button class="search_btn" onClick={handleSearchClick}>
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                        {window.innerWidth > 912 && <div></div>}
                        {/* {
                            window.innerWidth <= 912 &&
                            <ProductLeftContent currentSelection={currentSelection} setCurrentSelection={setCurrentSelection} setPLPData={setPLPData} initialLoad={initialLoad} setInitialLoad={setInitialLoad} loader={loader} setLoader={setLoader} type="mobile" activeSec="MyProduct" />
                        } */}
                        {
                            window.innerWidth <= 912 &&
                            <div className='reminder_filter_mobile'>
                                <div className='purchase_filter_heading'>
                                    <div className='filter_icon'> <img src={statusIcon} /></div>
                                    <div>{t("STATUS")}</div>
                                </div>
                                <div className='purchase_filter_list'>
                                    <div className={`filter-item ${filter === 'All' ? 'active' : ''}`} onClick={() => handleFilterChange('All')}>
                                        {t('All')}
                                    </div>
                                    <div className={`filter-item ${filter === 'Available' ? 'active' : ''}`} onClick={() => handleFilterChange('Available')}>
                                        {t('Available')}
                                    </div>
                                    <div className={`filter-item ${filter === 'Unavailable' ? 'active' : ''}`} onClick={() => handleFilterChange('Unavailable')}>
                                        {t('Unavailable')}
                                    </div>
                                </div>
                            </div>}
                    </div>
                    <div className='purchase_filter'>
                        <div className='purchase_filter_heading'>
                            <div className='filter_icon'> <img src={statusIcon} /></div>
                            <div>{t("STATUS")}</div>
                        </div>
                        <div className='purchase_filter_list'>
                            <div className={`filter-item ${filter === 'All' ? 'active' : ''}`} onClick={() => handleFilterChange('All')}>
                                {t('All')}
                            </div>
                            <div className={`filter-item ${filter === 'Available' ? 'active' : ''}`} onClick={() => handleFilterChange('Available')}>
                                {t('Available')}
                            </div>
                            <div className={`filter-item ${filter === 'Unavailable' ? 'active' : ''}`} onClick={() => handleFilterChange('Unavailable')}>
                                {t('Unavailable')}
                            </div>
                        </div>
                    </div>

                    <div className='PRODUCTPLP__DATA my_product_section'>
                        <div className='PRODUCTPLP__CONTENT'>
                            <div className='PRODUCTPLP__DATA'>
                                {!loader && PLPData?.length == 0 && <p className='no_product'>{t("NOPRODUCTAVAILABLE")}</p>}
                                <InfiniteScroll
                                    dataLength={PLPData?.length}
                                    next={loadNextPage}
                                    // hasMore={autoLoader * 20 < PLPDataCount}
                                    // loader={loader && [...Array(20)].map((_, index) => <NewCardSkeleton devicetype={"mobile"} key={index} />)}
                                    hasMore={Math.ceil(PLPDataCount / 20) == autoLoader ? false : true}
                                    loader={loader && [...Array(20)].map((_, index) => <NewCardSkeleton key={index}
                                        devicetype={window.innerWidth > 912 ? "desktop" : "mobile"}
                                    />)}
                                    scrollThreshold={0.5}
                                >
                                    {PLPData.map((data, i) => (
                                        <ProductCard
                                            key={i}
                                            data={data}
                                            setRefreshAPI={setRefreshAPI}
                                            refreshAPI={refreshAPI}
                                            devicetype={window.innerWidth > 912 ? "desktop" : "mobile"}
                                        />
                                    ))}
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyReminder;