import React from 'react'
import Skeleton from 'react-loading-skeleton'
import './PurchaseCardSkeleton.scss'

function PurchaseCardSkeleton({ devicetype }) {
  return (
    <div className={`purchaseCardSkeleton ${devicetype === "mobile" && "mobilebox"}`}>
        <Skeleton />
    </div>
  )
}

export default PurchaseCardSkeleton