import React, { useEffect, useState, useRef } from 'react'
import './MSettings.scss'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios';
import request from '../../../request';
import { useTranslation } from 'react-i18next';
import { useStateValue } from '../../../store/state';

function MSettings({ setShowSettings }) {
  const [{ }, dispatch] = useStateValue()
  const prevLangRef = useRef(); 
  const [chooselang, setchooselang] = useState(()=>{
    const storedLanguage = localStorage.getItem('storeid')
    return storedLanguage ? parseInt(storedLanguage, 10) : 2; // Default to 2 if not found
  });
  const { i18n, t } = useTranslation();

  // useEffect(async () => {
  //   let storeid = JSON.parse(localStorage.getItem("userdata"));
  //   if (storeid?.store_id == undefined) return
  //   setchooselang(storeid.store_id)
  // }, [localStorage.getItem("userdata")]);

  useEffect(() => {
    // Only fetch data if chooselang changes
    if (chooselang !== prevLangRef?.current && prevLangRef?.current != undefined) {
      const fetchData = () => {

        let TOKEN = localStorage.getItem('token');

        // Only set store ID when changing language
        localStorage.setItem("storeid", chooselang);

        if (
          localStorage.getItem("token") == null ||
          localStorage.getItem("userid") == null
        ) {
          return;
        }

        // API call to change language
        axios({
          method: "post",
          url: request.changelang,
          data: {
            customerId: localStorage.getItem("userid"),
            storeId: chooselang,
          },
          headers: {
            "Authorization": `Bearer ${TOKEN}`,
          },
        }).then(() => {
          // Fetch user data after changing language
          axios({
            method: "get",
            url: request.userData,
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
          }).then((userData) => {
            localStorage.setItem("userdata", JSON.stringify(userData.data));
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }).catch((userDataError) => {
            console.log(userDataError);
          });
        }).catch((error) => {
          console.log(error);
        });
      };

      fetchData(); // Call fetchData only when language changes
      prevLangRef.current = chooselang; // Update the ref with the current language
    }
  }, [chooselang]);

  const updateLanguage = (lang) => {
    if (lang === 1) {
      i18n.changeLanguage("en");
    } else if (lang === 2) {
      i18n.changeLanguage("th");
    }

    dispatch({
      type: "LG__CHANGE",
    });

    localStorage.setItem("storeid", lang);
    setchooselang(lang);
    prevLangRef.current = lang; // Update the ref with the current language
  };
  
  return (
    <div className='MSETTINGS'>
      <div className='MSETTINGS__TOP'>
        <svg onClick={() => setShowSettings(false)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_1_15238)">
            <path d="M17.5098 3.86961L15.7298 2.09961L5.83984 11.9996L15.7398 21.8996L17.5098 20.1296L9.37984 11.9996L17.5098 3.86961Z" fill="#1C304A" fill-opacity="0.52" />
          </g>
          <defs>
            <clipPath id="clip0_1_15238">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <h5 onClick={() => setShowSettings(false)}>{t("Setting")}</h5>
      </div>

      <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasBottomLabel">{t("Select")}</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <hr />
        <div class="offcanvas-body small">
          {/* <p onClick={() => setchooselang(1)} className={`${localStorage.getItem('storeid') == "1" && "selectedLanguage"}`}>English</p>
          <p onClick={() => setchooselang(2)} className={`${localStorage.getItem('storeid') == "2" && "selectedLanguage"}`}>ภาษาไทย</p> */}
            <p className='lg__thai' onClick={() => updateLanguage(2)}>ภาษาไทย</p>
            <p className='lg__eng' onClick={() => updateLanguage(1)}>English</p>
        </div>
      </div>

      <div className='MACCOUNT__SETTINGS__MENU'>

        <p data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
          <Link>
            <span>{t("Language")}</span>
            <span className='CURRENT__LANGUAGE'>
              {localStorage.getItem('storeid') == "1" ? "English" : "Thai"}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_1_15171)">
                <path d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z" fill="#192739" fill-opacity="0.94" />
              </g>
              <defs>
                <clipPath id="clip0_1_15171">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </p>
        <hr />
        <p>
          <Link to="/changepassword">
            <span>Change Password</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_1_15171)">
                <path d="M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z" fill="#192739" fill-opacity="0.94" />
              </g>
              <defs>
                <clipPath id="clip0_1_15171">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </p>
      </div>
    </div>
  )
}

export default MSettings