import React, { useCallback, useEffect, useState } from 'react'
import './Purchase.scss'
import { GETPRODUCTPLP } from '../../store/PLP/Action'
import { useStateValue } from '../../store/state'
import NewCardSkeleton from '../../components/Skeleton/NewCardSkeleton/NewCardSkeleton'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component';
import Favourite from '../../assets/images/MyProduct/favorite_border.png'
import FavouriteActive from '../../assets/images/MyProduct/favorite_border_active.png'
import bookmark from '../../assets/images/MyProduct/bookmark_border.png'
import bookmarkActive from '../../assets/images/MyProduct/bookmark_border_active.png'
import Vector from '../../assets/images/MyProduct/Vector.png'
import VectorActive from '../../assets/images/MyProduct/Vector_active.png'
import shoppingBag from '../../assets/images/MyProduct/shopping_bag.png';
import shoppingBagActive from '../../assets/images/MyProduct/shopping_bag_active.png';
import filterIcon from '../../assets/images/purchase/filter_Icon.png';
import MyReminder_icon from '../../assets/images/MyProduct/MyReminder_icon.png';
import active_MyReminder_icon from '../../assets/images/MyProduct/active_MyReminder_icon.png';
import ProductLeftContent from '../Product/ProductLeftContent'
import PurchaseCard from '../../components/PurchaseCard/PurchaseCard'
import { debounce } from '@mui/material'
import PurchaseCardSkeleton from '../../components/Skeleton/PurchaseCardSkeleton/PurchaseCardSkeleton'

// Image mapping for active and inactive states in sidebar_1
const images = {
  MyProduct: { inactive: shoppingBag, active: shoppingBagActive },
  MyPurchase: { inactive: Vector, active: VectorActive },
  MyWishlist: { inactive: bookmark, active: bookmarkActive },
  MyReminder: { inactive: MyReminder_icon, active: active_MyReminder_icon },
  MyFavourite: { inactive: Favourite, active: FavouriteActive },
};

const Purchase = () => {
  const [{ PRODUCTPLPDATA, }, dispatch] = useStateValue();
  const [loader, setLoader] = useState(false);
  const [PLPData, setPLPData] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);
  const [autoLoader, setAutoLoader] = useState(1)
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState('');
  const [daysSelect, setDaysSelect] = useState("0");
  const [typeSelect, setTypeSelect] = useState("A");
  const [currentSelection, setCurrentSelection] = useState({});

  useEffect(() => {
    setLoader(true);
    setPLPData([]);
    setInitialLoad(false);
  }, [daysSelect, currentSelection])

  // API call here - Search, Auto loader
  const fetchProducts = async () => {
    try {
      setLoader(true);
      await GETPRODUCTPLP(dispatch, TOKEN, COMPANYID, USERID, STOREID, currentSelection?.ID || "", currentSelection?.subID || "", "", "", "", "", setLoader, autoLoader,
        "", "", "", "", daysSelect, searchQuery, typeSelect);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
    }
  };

  // Handle search button click
  const handleSearchClick = () => {
    fetchProducts();
  };

  // Debounced fetch function
  const debouncedFetchProducts = useCallback(debounce(() => {
    fetchProducts();
  }, 300), []);


  // // Handle search input change
  const handleSearchChange = (event) => {
    setAutoLoader(1)
    const query = event?.target?.value;
    setSearchQuery(query);
    if (query) {
    } else {
      debouncedFetchProducts();
      setLoader(true);
      setPLPData([]);
    }
  };


  let TOKEN = localStorage.getItem('token')
  let COMPANYID = localStorage.getItem('companyid')
  let USERID = localStorage.getItem('userid')
  let STOREID = localStorage.getItem('storeid')

  // Effect to fetch initial products or update when searchQuery changes
  useEffect(() => {
    if (autoLoader > 1) {
      fetchProducts();
    }
  }, [autoLoader]);

  const loadNextPage = () => {
    setAutoLoader(prevAutoLoader => prevAutoLoader + 1);
  }

  // Set PLP data
  useEffect(() => {
    if (autoLoader === 1 && searchQuery) {
      if (PRODUCTPLPDATA?.products) {
        setPLPData(PRODUCTPLPDATA.products);
      }
    } else {
      PRODUCTPLPDATA?.products?.filter(data => (
        setPLPData(prevState => [
          ...prevState,
          data
        ])
      ))
    }
  }, [PRODUCTPLPDATA])

  return (
    <div className='PRODUCTPLP'>
      <div className='PRODUCTPLP__HEADER'>
        {
          window.innerWidth > 912 &&
          <ProductLeftContent currentSelection={currentSelection} setCurrentSelection={setCurrentSelection} setPLPData={setPLPData} initialLoad={initialLoad} setInitialLoad={setInitialLoad} loader={loader} setLoader={setLoader} type="web" activeSec="MyPurchase" daysSelect={daysSelect} typeSelect={typeSelect} setTypeSelect={setTypeSelect} setDaysSelect={setDaysSelect} searchQuery={searchQuery} />
        } <div className='PURCHASEPLP_RIGHT_SIDE'>
          <div className="my_product_content_header">
            <div className="my_product_content_header_first">
              {window.innerWidth > 912 ? 
                <div className='heading'> {t("My Purchase")}</div> :
                window.innerWidth <= 912 && localStorage.getItem('device') !== 'true' && 
                <div className='heading_mobile'>
                  {t("My Purchase")}
                  <span>
                    <a href="/myproduct"><img src={images.MyProduct.inactive} /></a>
                    <a href="/myreminder"><img src={images.MyReminder.inactive} /></a>
                    <a href="/myfavourite"><img src={images.MyFavourite.inactive} /></a>
                  </span>
                </div>
              }
              <div className='info'>
                {t("Your purchase history is here")}
              </div>
            </div>
            <div class="search_box_wrapper">
              <input
                class="search_input"
                placeholder={t("Search Product, Brand")}
                name="srch-term"
                id="srch-term"
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleSearchClick();
                  }
                }}
              />
              <button class="search_btn" onClick={handleSearchClick}>
                <i className="fa fa-search"></i>
              </button>
            </div>
            {
              window.innerWidth <= 912 &&
              <ProductLeftContent currentSelection={currentSelection} setCurrentSelection={setCurrentSelection} setPLPData={setPLPData} initialLoad={initialLoad} setInitialLoad={setInitialLoad} loader={loader} setLoader={setLoader} type="mobile" activeSec="MyPurchase" daysSelect={daysSelect} typeSelect={typeSelect} setTypeSelect={setTypeSelect} setDaysSelect={setDaysSelect} searchQuery={searchQuery} />
            }
          </div>
          <div className='purchase_filter'>
            <div className='purchase_filter_heading'>
              <div className='filter_icon'> <img src={filterIcon} /></div>
              <div>{t("Filter")}</div>
            </div>
            <div className='purchase_filter_list'>
              <div className={`clickable ${daysSelect === "0" && typeSelect === "A" ? "active" : ""}`} onClick={() => { setDaysSelect("0"); setTypeSelect("A"); }}>{t("All")}</div>
              <div className={`clickable ${daysSelect === "7" && typeSelect === "W" ? "active" : ""}`} onClick={() => { setDaysSelect("7"); setTypeSelect("W"); }} >{t("Within 1 week")}</div>
              <div className={`clickable ${daysSelect === "30" && typeSelect === "W" ? "active" : ""}`} onClick={() => { setDaysSelect("30"); setTypeSelect("W"); }} >{t("Within 1 month")}</div>
              <div className={`clickable ${daysSelect === "31" && typeSelect === "O" ? "active" : ""}`} onClick={() => { setDaysSelect("31"); setTypeSelect("O"); }} >{t("Over 1 month")}</div>
              <div className={`clickable ${daysSelect === "90" && typeSelect === "O" ? "active" : ""}`} onClick={() => { setDaysSelect("90"); setTypeSelect("O"); }} >{t("Over 3 month")}</div>
            </div>
          </div>
          <div className='purchase_content'>
            <div className='PURCHASEPLP__DATA'>
              {!loader && PLPData?.length == 0 && <p className='no_product'>{t("NOPRODUCTAVAILABLE")}</p>}
              <InfiniteScroll
                dataLength={PLPData?.length}
                next={loadNextPage}
                hasMore={Math.ceil(PRODUCTPLPDATA?.product_count / 20) == autoLoader ? false : true}
                loader={loader && [...Array(20)].map((_, index) => <PurchaseCardSkeleton
                  key={index}
                  devicetype={window.innerWidth > 912 ? "desktop" : "mobile"} />)}
                scrollThreshold={0.5}
              >
                {PLPData?.map((data, i) => (
                  <PurchaseCard
                    key={i}
                    data={data}
                    devicetype={window.innerWidth > 912 ? "desktop" : "mobile"}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Purchase;